import React from 'react';
import { Box, FormControl, TextField, Typography } from '@mui/material';
import { customTheme } from '../theme';
import { label } from 'aws-amplify';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: any;
  value?: string;
  title?: string;
  disabled?: boolean;
  titleStyle?: any;
  inputStyle?: any;
  inputType?: string;
  inputPlaceholder?: any;
  label?: string;
}

export function CustomInput({
  onChange,
  value = '',
  title,
  disabled: status = false,
  titleStyle,
  inputStyle,
  inputPlaceholder = '',
  inputType,
  label,
}: Props) {
  return (
    <FormControl fullWidth>
      <Box mb={1}>
        <Typography fontFamily={customTheme.fontFamily} style={{ ...titleStyle }}>{title}</Typography>
      </Box>

      <TextField
        value={value}
        disabled={status}
        placeholder={inputPlaceholder}
        label={label}
        inputProps={{
          style: {
            fontFamily: customTheme.fontFamily,
            height: '40px',
            padding: '0px',
            paddingLeft: '12px',
            backgroundColor: 'white',
            ...inputStyle,
          },
        }}
        InputLabelProps={{
          shrink: true, 
        }}
        onChange={e => {
          onChange(e.target.value);
        }}
        type={inputType}
      />
    </FormControl>
  );
}
