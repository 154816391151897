import {
  Box,
  Dialog,
  DialogProps,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { CustomButton } from '../elements/button';
import { CustomInput } from '../elements/input';
import { useMutationHook } from '../hooks/use-mutation';
import { UPDATE_PATRON_MUTATION } from '../graphql/mutation';
import { extractAllErrorMesasges } from '../utils/graphql';
import { UserContext } from '../context/user-context';
import { PhoneNumberInput } from './phone-input';

interface Props extends DialogProps {
  title?: string;
  body?: string;
  onClose?: () => void;
  onSaveAndContinue: () => void;
}

const local_storage_key = 'locationDetails';

export const LocationDetailsModal = ({
  open,
  onSaveAndContinue,
  onClose,
}: Props) => {
  const { t } = useTranslation(['patron_portal', 'common']);
  const userContext = useContext(UserContext);
  const patron = userContext?.patron;

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [buildingNumber, setBuildingNumber] = useState<string>('');
  const [floorNumber, setFloorNumber] = useState<string>('');
  const [isFormComplete, setFormComplete] = useState(false);
  const [err, setError] = useState('');

  const storedData = localStorage.getItem(local_storage_key);
  useEffect(() => {
    if (userContext.isLoggedIn && patron?.locationDetails) {
      setPhoneNumber(userContext.phoneNumber || '');
      setAddress(patron.locationDetails.address || '');
      setBuildingNumber(patron.locationDetails.buildingNumber || '');
      setFloorNumber(patron.locationDetails.floorNumber || '');
    } else if (storedData) {
      const { phoneNumber, address, buildingNumber, floorNumber } = JSON.parse(storedData);
      setPhoneNumber(phoneNumber || '');
      setAddress(address || '');
      setBuildingNumber(buildingNumber || '');
      setFloorNumber(floorNumber || '');
    }
  }, [userContext.isLoggedIn, patron?.locationDetails, storedData]);

  useEffect(() => {
    const isValidPhoneNumber = phoneNumber.length >= 10;
    if (isValidPhoneNumber && buildingNumber && floorNumber) {
      setFormComplete(true);
    } else {
      setFormComplete(false);
    }
  }, [phoneNumber, buildingNumber, floorNumber]);

  const saveToLocalStorage = () => {
    const data = { phoneNumber, address, buildingNumber, floorNumber };
    localStorage.setItem(local_storage_key, JSON.stringify(data));
    return data;
  };

  const { mutate: updatePatron, loading: updatePatronLoading } =
    useMutationHook({
      mutation: UPDATE_PATRON_MUTATION,
      onError: error => {
        setError(extractAllErrorMesasges(error).join(', '));
      },
      onCompleted: data => {
        userContext.updatePatronLocationDetails({
          address,
          buildingNumber,
          floorNumber,
        });
      },
    });

  const handleSaveAndContinue = () => {
    const locationDetails = {
      address,
      buildingNumber,
      floorNumber,
    };

    userContext.updatePatronLocationDetails(locationDetails);
    updatePatron({
      variables: {
        id: patron?._id,
        patron: {
          name: patron?.name,
          locationDetails: {
            address: address,
            buildingNumber: buildingNumber,
            floorNumber: floorNumber,
          },
        },
      },
    });
    saveToLocalStorage();
    onSaveAndContinue();
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <Grid container direction={'row'} justifyContent={'center'}>
        <DialogTitle>
          Location Details
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Divider sx={{ width: '100%' }} />

        <Grid container spacing={2} px={4} mt={2}>
          <Grid item xs={12}>
            <PhoneNumberInput
              value={phoneNumber}
              onChange={(value: any) => {
                setPhoneNumber(value);
              }}
            />
            <Typography
              sx={{ color: 'black', textAlign: 'center', fontSize: '0.875rem' }}
            >
              This is only used for this order delivery
            </Typography>
          </Grid>

 {/* //////////////////////////////////// this block of code might be useful for future purpose ///////////////// */}
          {/* <Grid item xs={12}>
            <CustomInput
              label="Address"
              value={address}
              onChange={(value: any) => setAddress(value)}
              inputPlaceholder="Enter your address"
              inputType="text"
            />
          </Grid> */}

            {/* <Grid item xs={6}>
              <CustomInput
                label="Suburb"
                value={suburb}
                onChange={(value: any) => setSuburb(value)}
                inputPlaceholder="Enter your suburb"
                inputType="text"
              />
            </Grid> */}
            {/* <Grid item xs={6}>
              <CustomInput
                label="Post Code"
                value={postcode}
                onChange={(value: any) => setPostcode(value)}
                inputPlaceholder="Enter your post code"
                inputType="text"
              />
            </Grid> */}
{/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
          <Grid container item xs={12} spacing={2} mb={4}>
            <Grid item xs={6}>
              <CustomInput
                label="Building No."
                value={buildingNumber}
                onChange={(value: any) => setBuildingNumber(value)}
                inputPlaceholder="Enter Building No."
                inputType="text"
                inputStyle={{fontSize: "13px"}}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomInput
                label="Floor No."
                value={floorNumber}
                onChange={(value: any) => setFloorNumber(value)}
                inputPlaceholder="Enter Floor No."
                inputType="text"
                inputStyle={{fontSize: "13px"}}
              />
            </Grid>
            
          </Grid>
        </Grid>
       
        <Grid mt={{ md: 'auto', xs: 2 }} mb={'20px'} item xs={12} mx={4}>
          <CustomButton
            onClick={handleSaveAndContinue}
            title="Save & Continue"
            disabled={!isFormComplete}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};
