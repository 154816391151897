import React, { useState } from 'react';
import { createContext, ReactElement } from 'react';
import { Patron } from '../types/user';

type LocationDetail = {
  address: string;
  buildingNumber: string;
  floorNumber: string;
};

type UserContextType = {
  cognitoUser?: any;
  token?: any;
  groups?: string[];
  email?: string;
  phoneNumber?: string;
  emailVerified?: boolean;
  phoneVerified?: boolean;
  cognitoID?: boolean;
  patron?: Patron;
  isLoggedIn?: boolean;
  // eslint-disable-next-line no-unused-vars
  setUser: (user: any) => void;
  setPatron: (patron: Patron) => void;
  updatePatronLocationDetails: (locationDetails: LocationDetail) => void;
  clear: () => void;
};

export const UserContext = createContext<UserContextType>({
  // eslint-disable-next-line no-unused-vars
  setUser: (user: any) => {},
  clear: () => {},
  setPatron: (patron: Patron) => {},
  updatePatronLocationDetails: () => {},
});

interface Props {
  children: ReactElement;
}

function UserContextProvider({ children }: Props) {
  const [user, setUser] = useState<any>();
  const [patron, setPatron] = useState<any>();

   const updatePatronLocationDetails = (locationDetails: LocationDetail) => {
     setPatron((prevPatron:Patron) => ({
       ...prevPatron,
       locationDetails, 
     }));
   };

  const payload = user?.signInUserSession?.idToken?.payload || {};

  const value: UserContextType = {
    cognitoUser: user,
    clear: () => setUser(undefined),
    patron,
    setPatron,
    setUser,
    updatePatronLocationDetails,
    token: user?.signInUserSession?.accessToken?.jwtToken,
    isLoggedIn: !!user,
    cognitoID: payload?.sub,
    groups: payload['cognito:groups'],
    phoneNumber: payload?.phone_number,
    phoneVerified: payload?.phone_number_verified,
    email: payload?.email,
    emailVerified: payload?.email_verified,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export default UserContextProvider;
