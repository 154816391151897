import { useMediaQuery, Grid, Box } from '@mui/material';
import i18next from 'i18next';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { VendorOrder, OrderStatus, ServiceMethod } from '../../types/order';
import { Text } from '../../elements/text';
import { AppContext } from '../../context/app-context';
import { VenueTypes } from '../../pages/home/home';
import { VendorOrderCard } from '../vendor-order-card';

interface IPastOrderTrackingInfoProps {
  order: VendorOrder;
  orderStatus: OrderStatus;
  index: number;
  serviceMethod: string;
}

export const PastOrderTrackingInfo = ({
  order,
  orderStatus,
  serviceMethod,
  index,
}: IPastOrderTrackingInfoProps) => {
  const { t } = useTranslation(['patron_portal', 'common']);
  const isNarrowScreen = useMediaQuery('(max-width: 410px)');
  console.log("order:", order)
  const { venueType, seat } = useContext(AppContext);


  const getOrderTrackingText = () => {
    switch (orderStatus) {
      case (OrderStatus.PAYMENT_PENDING, OrderStatus.RECEIVED):
        return i18next.t('patron_portal.order.msgOrderStatusRecieved', {
          ns: 'patron_portal',
        });

      case OrderStatus.PREPARING:
        return i18next.t('patron_portal.order.msgOrderStatusPreparing', {
          ns: 'patron_portal',
        });

      case OrderStatus.ACCEPTED:
        return i18next.t('patron_portal.order.msgOrderStatusPreparing', {
          ns: 'patron_portal',
        });

      case OrderStatus.PREPARED: {
        let statusMessage = '';
        if (
          (venueType?.value === VenueTypes.DINE_IN ||
            venueType?.value === VenueTypes.HOTEL) &&
          seat?.value !== '0'
        ) {
          statusMessage = 'patron_portal.order.readyForDelivery';
        } else {
          statusMessage =
            serviceMethod === ServiceMethod.COLLECT_FROM_VENDOR
              ? 'patron_portal.order.msgOrderStatusPreparedCNC'
              : serviceMethod === ServiceMethod.VENDOR_DELIVERY
              ? 'patron_portal.order.msgOrderStatusPreparedVD'
              : 'patron_portal.order.msgOrderStatusPreparedTTS';
        }
        return i18next.t(statusMessage, {
          ns: 'patron_portal',
        });
      }
      case OrderStatus.READY_TO_COLLECT:
        return i18next.t(
          'patron_portal.order.msgOrderStatusReadyToCollectFull',
          {
            ns: 'patron_portal',
          },
        );
      case OrderStatus.ASSIGNED:
        return i18next.t('patron_portal.order.msgOrderStatusAssignedFull', {
          ns: 'patron_portal',
        });

      case OrderStatus.PICKED:
        return i18next.t('patron_portal.order.msgOrderStatusPicked', {
          ns: 'patron_portal',
        });

      case OrderStatus.AT_COLLECTION_POINT:
        return i18next.t(
          'patron_portal.order.msgOrderStatusAtCollectionPoint',
          {
            ns: 'patron_portal',
          },
        );

      case OrderStatus.DELIVERED:
        return i18next.t('patron_portal.order.msgOrderStatusDelivered', {
          ns: 'patron_portal',
        });

      case OrderStatus.COMPLETE:
        return i18next.t('patron_portal.order.msgOrderStatusDelivered', {
          ns: 'patron_portal',
        });

      case OrderStatus.PATRON_CANCELLED:
        return i18next.t('patron_portal.order.msgOrderStatusPatronCancelled', {
          ns: 'patron_portal',
        });

      case OrderStatus.REJECTED:
        return i18next.t('patron_portal.order.msgOrderStatusRejected', {
          ns: 'patron_portal',
        });

      case OrderStatus.PAYMENT_REJECTED:
        return i18next.t('patron_portal.order.msgOrderStatusPaymentFailed', {
          ns: 'patron_portal',
        });

      default:
        return '';
    }
  };

  return (
    <>
      <Grid
        container
        item
        xs={12}
        display={'flex'}
        margin={'auto'}
        position={'relative'}
        marginTop={'20px'}
      >
        <Box
          sx={{
            width: '100%',
          }}
          position={'absolute'}
          bottom={'0px'}
          borderRadius={'0px 0px 8px 8px'}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'space-between',
              my: '8px',
              mx: '8px',
              flexGrow: 1,
            }}
          >
            <Box>
              <Text
                text={order?.vendor?.tradingName}
                fontWeight={800}
                fontSize={18}
                style={{
                  lineHeight: 1.2,
                }}
              />
              <Text
                text={`Order #${order?.orderNumber}`}
                fontWeight={800}
                fontSize={18}
                style={{
                  lineHeight: 1.2,
                }}
              />
            </Box>
            <Box
              sx={{
                backgroundColor: '#cecece',
                borderRadius: '8px',
                paddingX: '8px',
                paddingY: '8px'
              }
              }>
                <Text
                text={getOrderTrackingText()}
                fontWeight={400}
                fontSize={11}
                style={{
                  lineHeight: 1.2,

                }}
              />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid container borderRadius={'8px'} position={'relative'}>
        <Grid container item xs={12}>
          <VendorOrderCard order={order} index={index} isPastOrder={true} />
        </Grid>
      </Grid>
    </>
  );
};