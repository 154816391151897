import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import { AppContext } from '../../context/app-context';
import { CustomInput } from '../../elements/input';
import { CustomSelect } from '../../elements/select';
import { Section, CollectionPoint } from '../../types';
import { Text } from '../../elements/text';
import { useTranslation } from 'react-i18next';
import { ServiceMethod } from '../../types/order';
import { IOModelVenueWithStores } from '../../types/io-models';
import { CustomButton } from '../../elements/button';
import { IVenueSectionDeliveryInfoMeta } from '../../pages/home/home';
import { StoreWithDetails } from '../vendor-menu/types';
import { useSnackbar } from 'notistack';

type ServiceMethodFormVisibility = {
  showPreferredServiceMethodField: boolean;
  showPreferredCollectionPointField: boolean;
  showRowAndSeatField: boolean;
};

interface IVenueSeatCaptureFormProps {
  venue: IOModelVenueWithStores;
  serviceAvailable: boolean;
  // allowedServiceMethods: string[]; // todo: enum
  serviceChoices: {
    serviceMethod: ServiceMethod | undefined;
    deliveryInfo: IVenueSectionDeliveryInfoMeta;
  };
  onLocationSelection: (
    serviceMethod: ServiceMethod | undefined,
    selection: IVenueSeatCaptureFormProps['serviceChoices']['deliveryInfo'],
  ) => void;
  vendors: StoreWithDetails[];
  refetchVendor: () => void;
}

export const VenueSectionSeatCaptureForm: React.FC<
  IVenueSeatCaptureFormProps
> = ({
  venue,
  serviceChoices,
  serviceAvailable,
  onLocationSelection,
  vendors,
  refetchVendor,
}) => {
  const { t } = useTranslation(['patron_portal', 'common']);
  const appContext = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const [updatedVendorStatus, setUpdatedVendorStatus] =
    useState<StoreWithDetails | null>(null);
  const [updatedVenue, setUpdatedVenue] = useState<StoreWithDetails | null>(
    null,
  );

  const [formOptionsVisibilityFlags, setFormVisibilityFlags] =
    useState<ServiceMethodFormVisibility>({
      showPreferredCollectionPointField: false,
      showPreferredServiceMethodField: false,
      showRowAndSeatField: false,
    });

  const [availableSections, setSections] = useState<Section[]>([]);
  const [availableCollectionPoints, setCollectionPoints] = useState<
    CollectionPoint[]
  >([]);
  const [availableServiceMethods, setAvailableSerMethods] = useState<
    ServiceMethod[]
  >([]);
  
  const [selectedServiceMethod, setSelectedServiceMethod] =
    useState<ServiceMethod>();
  const [draftDeliveryInfo, setDraftDeliveryInfo] =
    useState<IVenueSeatCaptureFormProps['serviceChoices']['deliveryInfo']>();
  const [isAValidLocationSelection, setLocSelectionValidity] = useState(false);

  useEffect(() => {
    // When the selected Venue changes, update available sections, CP. Also update selected Delivery/Service choices.
    if (venue) {
      const sections: Section[] =
        venue?.sections.filter((section: Section) => !section.isDeleted) || [];

      setSections(sections);

      if (serviceChoices?.deliveryInfo?.section) {
        const selectedSection = venue.sections.find((s: Section) => {
          return s.id === serviceChoices?.deliveryInfo?.section;
        });

        if (selectedSection) {
          prepareSectionAvailabilities(selectedSection);

          // Reset Delivery Choices (upto parent to set this correct based on the selected Venue)
          if (serviceChoices && serviceChoices?.deliveryInfo) {
            setDraftDeliveryInfo({ ...serviceChoices?.deliveryInfo });
          }

          if (serviceChoices && serviceChoices?.serviceMethod) {
            setSelectedServiceMethod(serviceChoices.serviceMethod);
          }
        }
      }
    } else {
      setSections([]);
      setCollectionPoints([]);
      setDraftDeliveryInfo(undefined);
      setSelectedServiceMethod(undefined);
    }
  }, [venue, serviceChoices]);

  // When user delivery / service method selections is updated by the user, check and disaply options according to the current selections
  useEffect(() => {
    let isValidLocationSelection = false;
    const formVisibilityFlagsUpdates: ServiceMethodFormVisibility = {
      showPreferredCollectionPointField: false,
      showPreferredServiceMethodField: false,
      showRowAndSeatField: false,
    };

    if (draftDeliveryInfo?.section) {
      // If current section allows multiple delivery methods....
      if (
        availableServiceMethods.length &&
        availableServiceMethods.length != 1
      ) {
        //  ....that are a combination of Delivery & Collect, show a Delivery Preference selection to the User, Or...
        if (availableServiceMethods.includes(ServiceMethod.DELIVERY))
          formVisibilityFlagsUpdates.showPreferredServiceMethodField = true;
        // if this is CNC and CP, show collection Points List
        else
          formVisibilityFlagsUpdates.showPreferredCollectionPointField = true;
      }

      // If the Service Method is already selected/set and it is Delivery, Show Delivery details inputs.
      if (selectedServiceMethod === ServiceMethod.DELIVERY) {
        formVisibilityFlagsUpdates.showRowAndSeatField = true;
      }

      // If the Service Method is already selected/set and that is not Delivery, and allowed methods includes CP, then show CP (or Vendor Colect) Options
      if (
        selectedServiceMethod &&
        selectedServiceMethod != ServiceMethod.DELIVERY &&
        selectedServiceMethod != ServiceMethod.COLLECT_FROM_VENDOR &&
        availableServiceMethods.includes(ServiceMethod.COLLECTION_POINT)
      ) {
        formVisibilityFlagsUpdates.showPreferredCollectionPointField = true;
      }
    }

    // Check if the current choices is a Valid complete choice

    console.log('----- ONNNN ');
    console.dir(draftDeliveryInfo);
    switch (selectedServiceMethod) {
      case ServiceMethod.COLLECT_FROM_VENDOR:
        if (
          draftDeliveryInfo?.section &&
          !draftDeliveryInfo.collectionPoint &&
          (!draftDeliveryInfo.row || draftDeliveryInfo.row == 'N/A') &&
          (!draftDeliveryInfo.seat || draftDeliveryInfo.seat == 'N/A')
        )
          isValidLocationSelection = true;
        break;
      case ServiceMethod.COLLECTION_POINT:
        if (
          draftDeliveryInfo?.section &&
          draftDeliveryInfo.collectionPoint &&
          (!draftDeliveryInfo.row || draftDeliveryInfo.row == 'N/A') &&
          (!draftDeliveryInfo.seat || draftDeliveryInfo.seat == 'N/A')
        )
          isValidLocationSelection = true;
        break;
      case ServiceMethod.DELIVERY:
        if (
          draftDeliveryInfo?.section &&
          draftDeliveryInfo.row &&
          draftDeliveryInfo.seat
        )
          isValidLocationSelection = true;
        break;
    }
    setLocSelectionValidity(isValidLocationSelection);
    setFormVisibilityFlags(formVisibilityFlagsUpdates);
  }, [draftDeliveryInfo, selectedServiceMethod]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const allStoresClosed = vendors.every(vendor => !vendor.isOpen);
      if (allStoresClosed) {
        console.log('All vendors are closed, refetching venue data');
        const updatedVenue: any = await refetchVendor();
        setUpdatedVendorStatus(updatedVenue.data.getVenue);
      } else {
        console.log('At least one vednor is open, no need to refetch');
        clearInterval(intervalId);
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, [refetchVendor, vendors]);

  // Methods

  const prepareSectionAvailabilities = (section: Section) => {
    const sectionServiceMethods = section?.serviceMethods || [];

    const sectionCollectionPoints =
      section && venue.collectionPoints
        ? [
            ...venue.collectionPoints.filter(
              cp =>
                !cp.isDeleted &&
                cp.serviceableSectionsIds?.includes(section.id),
            ),
          ]
        : [];

    // IF cp & cnc available together, use one dropdown (Unused now)
    if (
      sectionServiceMethods.includes(ServiceMethod.COLLECT_FROM_VENDOR) ||
      sectionServiceMethods.length === 0
    ) {
      sectionCollectionPoints.push({ id: '0', label: 'Collect from Vendor' });
    }

    setCollectionPoints(sectionCollectionPoints);
    setAvailableSerMethods(sectionServiceMethods);
  };

  const onSectionChange = (section: Section) => {
    console.log(section.label);
    prepareSectionAvailabilities(section);
    setDraftDeliveryInfo({
      // isValid: isCollectOnly,
      section: section.id,
      collectionPoint: undefined,
      row: undefined,
      seat: undefined,
    });
    setSelectedServiceMethod(
      section?.serviceMethods?.length == 1
        ? section?.serviceMethods[0]
        : undefined,
    );
  };

  const onServiceMethodChange = (serviceMethod: ServiceMethod) => {
    setSelectedServiceMethod(serviceMethod);
  };

  const onCollectionPointChange = (cpID: string) => {
    if (draftDeliveryInfo) {
      if (cpID === '0') {
        setSelectedServiceMethod(ServiceMethod.COLLECT_FROM_VENDOR);
        return;
      } else {
        const cp = availableCollectionPoints.find(cp => cp.id === cpID);
        if (cp) {
          setSelectedServiceMethod(ServiceMethod.COLLECTION_POINT);
          setDraftDeliveryInfo({
            ...draftDeliveryInfo,
            collectionPoint: cp.id,
          });
        }
      }
    }
  };

  const onRowSeatChange = (row = '', seat = '') => {
    if (draftDeliveryInfo) {
      setSelectedServiceMethod(ServiceMethod.DELIVERY);
      setDraftDeliveryInfo({
        ...draftDeliveryInfo,
        row: row,
        seat: seat,
      });
    }
  };

  const getServiceMethodsOptions = () => {
    const defaultMethodOptions = [
      {
        id: ServiceMethod.DELIVERY as string,
        label: t('patron_portal.seatSelectionForm.ttsOptionMessage', {
          ns: 'patron_portal',
        }),
      },
      {
        id: ServiceMethod.COLLECT_FROM_VENDOR as string,
        label: t('patron_portal.seatSelectionForm.cncOptionMessage', {
          ns: 'patron_portal',
        }),
      },
      {
        id: ServiceMethod.COLLECTION_POINT as string,
        label: t('patron_portal.seatSelectionForm.cpOptionMessage', {
          ns: 'patron_portal',
        }),
      },
    ];

    const serviceMethodOptions = defaultMethodOptions.filter(option =>
      availableServiceMethods.includes(option.id as ServiceMethod),
    );
    return serviceMethodOptions;
  };

  const handleSectionChange = (value: string) => {
    const section = availableSections.find(s => s.id === value);
    if (section) onSectionChange(section);
  };

  const getServiceMethodMessage = () => {
    switch (selectedServiceMethod) {
      case ServiceMethod.COLLECT_FROM_VENDOR:
        return t('patron_portal.seatSelectionForm.cncMessage', {
          ns: 'patron_portal',
        });

      case ServiceMethod.COLLECTION_POINT:
        return t('patron_portal.seatSelectionForm.cpMessage', {
          ns: 'patron_portal',
        });

      default:
        return '';
    }
  };

  const onLetsGo = () => {
    const allVendorsClosed = vendors.every(vendor => !vendor.isOpen);
    if (allVendorsClosed || vendors.length < 0) {
      console.log('inside if condition');
      enqueueSnackbar('Store is currently closed or unavailable.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    }
    // todo: Validate
    else if (selectedServiceMethod) {
      onLocationSelection(selectedServiceMethod, {
        collectionPoint:
          selectedServiceMethod === ServiceMethod.COLLECTION_POINT
            ? draftDeliveryInfo?.collectionPoint
            : undefined,
        row:
          selectedServiceMethod === ServiceMethod.DELIVERY
            ? draftDeliveryInfo?.row
            : undefined,
        seat:
          selectedServiceMethod === ServiceMethod.DELIVERY
            ? draftDeliveryInfo?.seat
            : undefined,
        section: draftDeliveryInfo?.section,
      });
    }
  };

  return (
    <>
      <CustomSelect
        onChange={handleSectionChange}
        data={availableSections}
        title={
          appContext.whitelabelConfig?.value?.venueSettings?.sectionLabel ||
          t('patron_portal.seatSelectionForm.stadiumSection', {
            ns: 'patron_portal',
          })
        }
        value={draftDeliveryInfo?.section}
      />
      {formOptionsVisibilityFlags &&
        formOptionsVisibilityFlags.showPreferredServiceMethodField && (
          <CustomSelect
            onChange={onServiceMethodChange}
            data={getServiceMethodsOptions()}
            title={t(
              'patron_portal.seatSelectionForm.preferredServiceMethodLabel',
              {
                ns: 'patron_portal',
              },
            )}
            value={selectedServiceMethod}
          />
        )}
      {formOptionsVisibilityFlags &&
        formOptionsVisibilityFlags.showPreferredCollectionPointField && (
          <CustomSelect
            onChange={(value: string) => {
              onCollectionPointChange(value);
            }}
            data={availableCollectionPoints || []}
            title={t('patron_portal.seatSelectionForm.collectionPoint', {
              ns: 'patron_portal',
            })}
            value={draftDeliveryInfo?.collectionPoint}
          />
        )}
      <Text
        text={getServiceMethodMessage()}
        fontSize={16}
        fontWeight={400}
        style={{
          lineHeight: '24px',
        }}
      />
      {formOptionsVisibilityFlags &&
        formOptionsVisibilityFlags.showRowAndSeatField && (
          <>
            <CustomInput
              onChange={(value: string) => {
                onRowSeatChange(value, draftDeliveryInfo?.seat);
              }}
              title={t('common.row', { ns: 'common' })}
              value={draftDeliveryInfo?.row}
            />

            <CustomInput
              onChange={(value: string) => {
                onRowSeatChange(draftDeliveryInfo?.row, value);
              }}
              title={t('common.seat', { ns: 'common' })}
              value={draftDeliveryInfo?.seat}
            />
          </>
        )}
      <Box flexGrow={1} mt={2}>
        <CustomButton
          disabled={!isAValidLocationSelection}
          title={t('patron_portal.seatSelectionForm.submitButtonLabel', {
            ns: 'patron_portal',
          })}
          onClick={onLetsGo}
        />
      </Box>
    </>
  );
};
