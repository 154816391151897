/*
 * ---------------------------------------------------------------
 * This file needs to be inlined with the file generated in backend
 * ---------------------------------------------------------------
 */

import { CustomisationOptionGroup } from './customisation';
import { ServiceMethod } from './order';

/* tslint:disable */
/* eslint-disable */
export type ChargesInput = {
  markupCharge: number;
  deliveryCharge: number;
  serviceCharge: number;
};

export type ItemQueryParams = {
  filter: ItemFilter;
};

export type ItemFilter = {
  vendorID?: Nullable<string>;
  showInMenu?: Nullable<boolean>;
};

export type ItemInput = {
  name: string;
  description: string;
  price: number;
  imageUrl: string;
  vendorID: string;
  outOfStock: boolean;
};

export type PreOrderInputParams = {
  itemID: string;
  count: number;
};

export type PreOrderSummaryInput = {
  items: PreOrderInputParams[];
  venueId: string;
  isClickAndCollect: boolean;
};

export type RunnerQueryParams = {
  filter: RunnerFilter;
};

export type RunnerFilter = {
  sectionIDs?: Nullable<string[]>;
};

export type RunnerInput = {
  name: string;
  email: string;
  phoneNumber: string;
  address: string;
  tfn: string;
  bankAccount: string;
  superAnnuationFund: string;
  venueID?: Nullable<string>;
  sectionIDs?: Nullable<string[]>;
};

export type VendorQueryParams = {
  filter: VendorFilter;
};

export type VendorFilter = {
  venueID?: Nullable<string>;
  sectionIDs?: Nullable<string>;
};

export type VendorInput = {
  businessName: string;
  tradingName: string;
  imageUrl: string;
  abn: string;
  bankAccount: string;
  contactPerson: string;
  contactPhone: string;
  contactEmail: string;
  hoursOfOperation: string;
  venueID?: Nullable<string>;
  sectionIDs?: Nullable<string[]>;
};

export type VenueInput = {
  name: string;
  address: string;
  sections: SectionInput[];
};

export type SectionInput = {
  label: string;
  id?: Nullable<string>;
};

export type Charges = {
  markupCharge: number;
  deliveryCharge: number;
  serviceCharge: number;
};

export type Item = {
  _id: string; // tobe replaced with id
  id?: string; //  _id
  name: string;
  description: string;
  price: number;
  markupPrice: number;
  imageUrl: string;
  category: string;
  thumbImageUrl: string;
  vendorID: string;
  outOfStock: boolean;
  customisations: CustomisationOptionGroup[];
  serialNumber: number;
};

export type ItemSubTotalPricing = {
  subTotal: number;
  deliveryCharge: number;
  total: number;
};

export type ItemSubTotal = {
  _id: string;
  price: number;
  pricing: ItemSubTotalPricing;
};

export type SummaryItem = {
  _id: string;
  key: string;
  name: string;
  price: number;
  markupPrice: number;
  pricing: Pricing;
  category: string;
  additionalAttributes: AdditionalAttributes;
  totalPrice: number;
  imageUrl: string;
  vendorName: string;
  storeID?: string;
  vendorIsClosed: boolean;
  count: number;
  outOfStock: boolean;
  showInMenu: boolean;
  customisations: CustomisationOptionGroup[];
  storeIsClosed: boolean;
};

export type AdditionalAttributes = {
  stdDrinkEquivalence: number;
};

export type Pricing = {
  singleQtyPrice: number;
  subTotal: number;
  deliveryCharge: number;
  serviceCharge: number;
  total: number;
};

export type PreOrderSummary = {
  available: SummaryItem[];
  unavailable: SummaryItem[];
  pricing?: Pricing;
};

export type Runner = {
  _id: string;
  name: string;
  email: string;
  phoneNumber: string;
  address: string;
  tfn: string;
  bankAccount: string;
  superAnnuationFund: string;
  venueID?: Nullable<string>;
  sectionIDs?: Nullable<string[]>;
};

export type Vendor = {
  _id: string;
  businessName: string;
  tradingName: string;
  imageUrl: string;
  abn: string;
  bankAccount: string;
  contactPerson: string;
  contactPhone: string;
  contactEmail: string;
  hoursOfOperation: string;
  isDisabled: boolean;
  venueID?: Nullable<string>;
  sectionIDs?: Nullable<string[]>;
  isOpen: boolean;
};

export type Section = {
  label: string;
  id: string;
  isClickAndCollect: boolean;
  cncNavigationImageUrl?: string;
  isDeleted: boolean;
  serviceMethods: ServiceMethod[];
  venueCode?: string
};

export type Currency = {
  name: string;
  stripeCode: string;
  symbol: string;
};

export type CollectionPoint = {
  id: string;
  label: string;
  cpNavigationImageUrl?: string;
  serviceableSectionsIds?: string[];
  isDeleted?: boolean;
  venueCode?: string
};
export type DeliveryArea = {
  id: string;
  label: string;
  daNavigationImageUrl?: string;
  serviceableSectionsIds?: string[];
  isDeleted?: boolean;
  venueCode?: string
};
export type Venue = {
  id: string;
  name: string;
  address: string;
  sections: Section[];
  collectionPoints: CollectionPoint[];
  deliveryAreas: DeliveryArea[];
  venueCurrency: Currency;
  ttsBrandingImageUrl: string;
  cncBrandingImageUrl: string;
  vendorListImageUrl: string;
  venueLandscapeImageUrl: string;
  patronNavigationImageUrl?: string;
  isDeleted?: Boolean;
  isDisabled?: Boolean;
  minimumOrderPrice: number;
  venueType: string;
  venueCode: string;
  fixedVendorDeliveryCharge: number;
};


export enum CategoryType {
  ALCOHOL = 'ALCOHOL',
  OTHER = 'OTHER',
}

export enum NoticeName {
  AGE_CONFIRMATION = 'age-confirmation',
  ALCOHOL_PER_ORDER_LIMIT = 'alcohol-per-order-limit',
  ALCOHOL_24_HOUR_LIMIT = 'alcohol-24-hour-limit',
  ALCOHOL_ORDER_TIME_LIMIT = 'alcohol-order-time-limit',
  ALCOHOL_SERVICE_METHOD_LIMIT = 'alcohol-service-method-limit',
}

export type PatronConsent = {
  patronID?: string;
  consentName: string;
  declaration: string;
  ts: Date;
};

export interface Notice {
  name: string;
  minAge?: number;
  required: boolean;
  allowedQuantity?: number;
  requestedQuantity?: number;
  unit?: string;
  allowedInterval?: number;
  allowedServiceMethods?: string[];
  requestedServiceMethod?: string;
}

export interface OrderNoticesResponse {
  notices: Notice[];
}

export type CreatePaymentAgreementInput = {
  patronOrderId: string;
  payerDetails: AgreementPayerDetailsInput;
  description: string;
  agreementDays: number;
  maximumAmount: number;
}

type AgreementPayerDetailsInput = {
  name: string;
  patronId: string;
  payId: string;
  payIdType: PayIdType;
}

export type AgreementPayerDetails = {
  name: string;
  patronId: string;
  payId: string;
  payIdType: PayIdType;
}

export type PaymentAgreement = {
  startDate: string
  endDate: string
  createdAt: string
  frequency: string
  maximumAmount: string
  paymentAgreementToken: string
  clientTransactionId: string
  status: string
  payerDetails: AgreementPayerDetails
}

export enum PayIdType {
  PHONE = 'PHONE',
  EMAIL = 'EMAIL'
}


type Nullable<T> = T | null;
