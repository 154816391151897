import { Grid } from '@mui/material';
import { useContext, useState } from 'react';
import { Header } from '../components/header';
import ArrowBackIcon from '@mui/icons-material/ArrowBackRounded';
import { Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Text } from '../elements/text';
import { Loader } from '../elements/loader';
import { CustomInput } from '../elements/input';
import { CustomButton } from '../elements/button';
import { UPDATE_PATRON_MUTATION } from '../graphql/mutation';
import { extractAllErrorMesasges } from '../utils/graphql';
import { UserContext } from '../context/user-context';
import { LOGIN_ROUTE, VIEW_CART_ROUTE } from '../constants/routes';
import { useMutationHook } from '../hooks/use-mutation';

type Variables = {
  id: string;
  patron: {
    name: string;
    locationDetails?: {
      address: string;
      buildingNumber: string;
      floorNumber: string;
    };
  };
};

export const CapturePatronDetails = () => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const { t } = useTranslation(['patron_portal', 'common']);

  const patron = userContext?.patron;

  if (!userContext.phoneNumber || !patron?._id) {
    return <Navigate to={LOGIN_ROUTE} />;
  }

  const [name, setName] = useState(userContext?.patron?.name || '');
  const [err, setError] = useState('');

  const { mutate: updatePatron, loading: updatePatronLoading } =
    useMutationHook({
      mutation: UPDATE_PATRON_MUTATION,
      onError: error => {
        setError(extractAllErrorMesasges(error).join(', '));
      },
      onCompleted: data => {
        navigate(VIEW_CART_ROUTE);
        window.location.reload();
      },
    });

  const patronName = name.trim();

  const local_storage_key = 'locationDetails';
  const storedData = localStorage.getItem(local_storage_key);
  const locationDetails = storedData ? JSON.parse(storedData) : null;

  const submit = () => {
    const variables: Variables = {
      id: patron?._id,
      patron: {
        name: patronName,
      },
    };

    if (locationDetails) {
      variables.patron.locationDetails = {
        address: locationDetails.address,
        buildingNumber: locationDetails.buildingNumber,
        floorNumber: locationDetails.floorNumber,
      };
    }

    updatePatron({ variables });
  };

  return (
    <Grid container>
      <Loader visible={updatePatronLoading} />
      <Grid item xs={12}>
        <Header
          backIconRoute={VIEW_CART_ROUTE}
          middleComponent={
            <Text
              text={t('patron_portal.patron.msgCaptureDetails', {
                ns: 'patron_portal',
              })}
              fontSize={18}
              fontWeight={700}
            />
          }
        />
      </Grid>

      <Grid p={2} container rowGap={2} item xs={12} sm={6} m="auto">
        <Grid xs={12} item>
          <Text
            fontWeight={700}
            fontSize={20}
            text={t('patron_portal.patron.msgEnterDetails', {
              ns: 'patron_portal',
            })}
          />
        </Grid>
        <Grid xs={12} mt={2} item>
          <CustomInput
            onChange={setName}
            value={name}
            title={t('common.label.name', { ns: 'common' })}
          />
        </Grid>
        <Grid xs={12} item>
          {err && (
            <Text fontWeight={700} fontSize={14} text={err} isError={true} />
          )}
        </Grid>

        <Grid xs={12} item>
          <CustomButton
            title={t('common.label.submit', { ns: 'common' })}
            disabled={!name || name.length < 2}
            onClick={submit}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
