import { useQuery } from '@apollo/client';
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import compact from 'lodash/compact';
import concat from 'lodash/concat';
import flatten from 'lodash/flatten';
import flow from 'lodash/flow';
import isNil from 'lodash/isNil';
import values from 'lodash/values';

import { useSnackbar } from 'notistack';
import { useContext, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CartController } from '../cart-controller';
import { AppContext } from '../../context/app-context';
import { CartContext, CartItem } from '../../context/cart-context';
import { CustomButton } from '../../elements/button';
import { Image } from '../../elements/image';
import { Loader } from '../../elements/loader';
import { Text } from '../../elements/text';
import { CALCULATE_ITEM_SUB_TOTAL, GET_ITEM } from '../../graphql/query';
import { CategoryType, Item, ItemSubTotal, ItemSubTotalPricing } from '../../types';
import { CustomisationOptionGroup } from '../../types/customisation';
import { getPriceWithCurrency } from '../../utils/charges';
import { Customisation } from './customisation';
import { customTheme } from '../../theme';
import { InformationModal } from '../information-modal';
import { ReactComponent as RSALimitIcon } from '../../assets/icons/RSALimitSticker.svg';
import { MenuItemGroupItem } from '../vendor-menu/types';

interface ViewItemProps {
  item: {itemID: string, storeID?: string};
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ViewItem = ({ item, setIsModalOpen }: ViewItemProps) => {
  // console.log(`View Item: ${JSON.stringify(item)}`)
  const { selectedVendor, serviceMethod, patronAdmitRSALimit, stadium } = useContext(AppContext);
  const { addToCart, cartLoading } = useContext(CartContext);
  const [itemCount, setItemCount] = useState<number>(1);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const formMethods = useForm();
  const { t } = useTranslation(['patron_portal', 'common']);

  const { watch } = formMethods;
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showAlcoholInfoModal, setAlcoholInfoModal] = useState(false);
  const selectedOptionsByOptionGroups = watch();
  const selectedCustomisations = useMemo(() => {
    return flow(
      values,
      compact,
      concat,
      flatten,
    )(selectedOptionsByOptionGroups);
  }, [selectedOptionsByOptionGroups]);

  const { data = { getItem: {} }, loading: itemLoading } = useQuery(GET_ITEM, {
    variables: {
      id: item.itemID,
    },
  });

  const { data: itemSubTotal, loading: totalsLoading } = useQuery(
    CALCULATE_ITEM_SUB_TOTAL,
    {
      variables: {
        itemSubTotalCalculatorInput:
        {
          item: {
            itemID: item.itemID,
            count: itemCount,
            customisations: selectedCustomisations,
            serviceMethod: serviceMethod?.value
          },
          venueId:stadium?.value
        },
        },
      skip: isNil(data?.getItem),
    },
  );

  const thisItem = data?.getItem as Item;
  const { customisations, markupPrice: itemPrice } = thisItem;
  const { pricing = {} } =
    (itemSubTotal?.calculateItemSubTotal as ItemSubTotal) || {};

  const { subTotal = 0 } = pricing as ItemSubTotalPricing;

  const handleAddToCartClick = () => {
    (thisItem.category === CategoryType.ALCOHOL && !patronAdmitRSALimit?.value) ? setAlcoholInfoModal(true) : continueAddToCart()
  }

  const continueAddToCart = async () => {
    if (checkMandatoryOptionNotSelected()) {
      enqueueSnackbar(t('patron_portal.item.msgToastSelectOptions', { ns: 'patron_portal' }),
        {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
      setError(true);
      setHelperText(
        t('patron_portal.item.msgSelectOneOption', { ns: 'patron_portal' }),
      );
      setErrorMessage(
        t('patron_portal.item.msgSelectMandatoryOption', {
          ns: 'patron_portal',
        }),
      );
    } else {
      setError(false);
      setHelperText('');
      const { v1: uuidV1 } = await import('uuid' /* webpackChunkName: "uuid" */);
      const cartItemID = uuidV1();
      const cartItem: CartItem = {
        id: thisItem._id,
        name: thisItem.name,
        storeID: item.storeID,
        count: itemCount,
        customisations: selectedCustomisations,
      };
      // console.log(`adding to cart ${JSON.stringify(cartItem)}`)
      addToCart(cartItemID, cartItem, itemCount, () => setIsModalOpen(false));
      enqueueSnackbar(
        t('patron_portal.item.msgItemAddSuccess', { ns: 'patron_portal' }),
        {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        },
      );
      setItemCount(1);
    }
  };

  const handleRSAInfoContinue = () => {
    patronAdmitRSALimit?.setValue(true)
    continueAddToCart()
    setAlcoholInfoModal(false)
  }

  const checkMandatoryOptionNotSelected = () => {
    let isMandatoryOptionAllGroupSelected = true;
    thisItem.customisations.forEach((og: CustomisationOptionGroup) => {
      if (og.type === 'singleSelect') {
        isMandatoryOptionAllGroupSelected = isMandatoryOptionAllGroupSelected
          ? isOptionGroupSelected(og)
          : false;
      }
    });
    return !isMandatoryOptionAllGroupSelected;
  };

  const isOptionGroupSelected = (optionGroup: CustomisationOptionGroup) => {
    let isMandatoryOptionSelected = false;
    optionGroup.options.forEach(option => {
      if (selectedCustomisations.find(id => option._id.toString() === id)) {
        isMandatoryOptionSelected = true;
      }
    });
    return isMandatoryOptionSelected;
  };

  return (
    <Grid
      container
      sx={{
        backgroundColor: '#f7f7f7',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        overflow: 'hidden',
        paddingBottom: '75px',
      }}
    >
      {showAlcoholInfoModal &&
        <InformationModal
          open={showAlcoholInfoModal}
          body={t('patron_portal.notice.msgRSASetsLimit', {
            ns: 'patron_portal',
          })}
          buttonTitle={t('patron_portal.modal.btnUnderstandContinue', {
            ns: 'patron_portal',
          })}
          bodyIcon={<RSALimitIcon />}
          handleContinue={handleRSAInfoContinue}
        />}
      {itemLoading && <Loader visible={true} />}
      <Grid item container xs={12} md={10} margin="auto" mt={{ md: 10 }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              height: { minHeight: { lg: '95vh' } },
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                height: { lg: 600 },
                width: { lg: 450 },
              }}
            >
              {thisItem?.imageUrl && (
                <Image
                  style={{
                    height: '100%',
                    borderRadius: 2,
                  }}
                  src={thisItem?.imageUrl}
                />
              )}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} mt={2} pl={2} pr={2}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                }}
              >
                <Text fontWeight={600} fontSize={18} text={thisItem.name}></Text>
                <Text fontSize={16} text={thisItem.description} fontWeight={500}></Text>
              </Box>
              <Box>
                <Typography fontFamily={customTheme.fontFamily} fontWeight={400} fontSize={12} color="red">
                  {errorMessage}
                </Typography>
              </Box>
              <Box
                mt={3}
                mb={2}
                display="flex"
                justifyContent={'space-between'}
              >
                <Box mt={1}>
                  <Text
                    fontSize={18}
                    fontWeight={400}
                    text={itemPrice === 0 ? t('common.label.selectOptions', { ns: 'common' }) : getPriceWithCurrency(itemPrice)}
                  ></Text>
                </Box>
                <CartController
                  count={itemCount}
                  onIncrement={() => setItemCount(itemCount + 1)}
                  onDecrement={() => {
                    if (itemCount > 1) {
                      setItemCount(itemCount - 1);
                    }
                  }}
                />
              </Box>
              <Divider sx={{ borderColor: 'black' }} />
            </Box>
          </Box>
        </Grid>
        <Grid item px={2} xs={12}>
          <FormProvider {...formMethods}>
            {customisations?.map(customisation => {
              return (
                <>
                  <Customisation
                    customisation={customisation}
                    itemCount={itemCount}
                    error={error}
                    helperText={helperText}
                  />
                  <Divider sx={{ borderColor: 'black' }} />
                </>
              );
            })}
          </FormProvider>
          <Box
            mt={2}
            mb={1}
            sx={{ display: 'flex', justifyContent: 'space-between', px: 1 }}
          >
            <Text
              text={t('common.text.total', { ns: 'common' })}
              fontWeight={600}
              fontSize={18}
            />
            <Typography
              maxWidth={'60%'}
              fontFamily={customTheme.fontFamily}
              fontWeight={600}
              fontSize={18}
              sx={{
                justifyContent: 'center',
                textAlign: 'center',
                alignItems: 'center',
              }}
            >
              {totalsLoading ? (
                <CircularProgress sx={{ width: '100%' }} size={20} />
              ) : (
                getPriceWithCurrency(subTotal)
              )}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Box
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          left: 0,
          minWidth: '50%',
          padding: '16px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          style={{
            width: '80%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CustomButton
            disabled={totalsLoading || cartLoading}
            title={t('patron_portal.item.labelAddToCart', {
              ns: 'patron_portal',
            })}
            onClick={handleAddToCartClick}
            loading={cartLoading}
            style={{
              boxShadow:
                totalsLoading || cartLoading
                  ? '0px 0px 0px 8px grey, 0px 0px 8px 12px #999'
                  : '0px 0px 0px 8px #2A2A2A, 0px 0px 8px 12px #999',
            }}
            loadingText={
              <CircularProgress
                sx={{ width: '100%', color: '#D2FF28' }}
                size={20}
              />
            }
          />
        </Box>
      </Box>
    </Grid>
  );
};
