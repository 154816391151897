type LocationDetail = {
  address: string;
  buildingNumber: string;
  floorNumber: string;
};

export interface Patron {
  _id: string;
  name?: string;
  email?: string;
  phoneNumber: string;
  phoneNumberVerified: boolean;
  cognitoID?: string;
  currentLoginMechanism?: string;
  locationDetails?: LocationDetail;
}

export enum UserType {
  patron = 'patron',
}
